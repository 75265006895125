import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import * as ApiCall from "../../API/ApiCalling";
import { widState } from "../../../Store/workdriveState";
import MySortableTree, {
  baseTreeData,
} from "../../Others/SortableTree/MySortableTree";
import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import SnackAlert from "../../RelatedList/srcfolder/components/WorkDrive/Others/SnackAlert";
const ZOHO = window.ZOHO;

const parseFieldFromCriteria = (criteria, previousfields) => {
  let fields = [...previousfields];
  const { comparator, field, group } = criteria;
  if (group?.length) {
    group.forEach((individualGroup) => {
      fields = [...parseFieldFromCriteria(individualGroup, fields)];
    });
  }

  if (comparator) {
    fields.push(field?.api_name);
  }

  return [...new Set(fields)];
};

function ProcessHistoricData({
  zapikey,
  orgid,
  connname,
  apikey,
  datacenterurl,
  zuid,
  previousModule,
  inProgressModuleList,
  isAdmin,
  handleClose,
  toggle,
  setToggle,
}) {
  const snap = useSnapshot(widState);
  const [loading, setLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [customViewList, setCustomViewList] = useState([]);
  const [folderFieldList, setFolderFieldList] = useState([]);
  const [urlFieldList, setUrlFieldList] = useState([]);
  const [folder, setFolder] = useState({});

  const [module, setModule] = useState({});
  const [customView, setCustomView] = useState({});
  const [folderField, setFolderField] = useState({});
  const [urlField, setUrlField] = useState({});

  const [checked, setChecked] = useState(false);
  const [willSkip, setWillSkip] = useState(true);
  const [isValid, setValid] = useState(false);
  const [treeData, setTreeData] = useState(baseTreeData);

  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [step, setStep] = useState(0);

  const handleChange = (event) => {
    setChecked((prev) => event.target.checked);
    if (event.target.checked && !urlField?.apiName) {
      setValid((prev) => false);
    }
  };

  const handleWillSkip = (event) => {
    setWillSkip((prev) => event.target.checked);
  };

  const saveTreeData = (data) => {
    const { key, response } = data;
    setTreeData((prev) => response);
  };

  const createCronJob = async () => {
    const data = {
      reqUrl: encodeURIComponent(
        process.env.REACT_APP_API_SERVER_URL + "/cron/execute"
      ),
      reqParams: {},
      reqHeaders: {
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        "content-type": "application/json",
        datacenterurl: datacenterurl,
      },
      reqBody: {
        funcType: "SaveRecordIds",
        module: module,
        customView: customView,
        folderField: folderField,
        urlField: urlField,
        folder: folder,
        treeData: treeData,
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        datacenterurl: datacenterurl,
        zapikey: zapikey,
        willSkip: true,
        page: 1,
        perPage: 5,
      },
      reqType: "GET",
    };
    // 1003.c64cad88655c146dbfd83a105d75d67a.083b155e009561e91078b15457940238

    try {
      const createJob = await ApiCall.createCronJob(data);
      if (createJob?.data) {
        // setMessage("Created Successfully..");
        // setSeverity("success");
        // setOpen(true);
        handleClose();
        setToggle(!toggle);
      } else {
        setMessage(createJob?.error);
        setSeverity("error");
        setOpen(true);
      }
    } catch (error) {}
  };

  async function getCustomView(module) {
    const { displayName: moduleDisplayName, apiName: moduleApiName } = module;
    try {
      const customViewResp = await ZOHO.CRM.META.getCustomViews({
        Entity: moduleApiName,
      });
      const customViews = customViewResp?.custom_views?.map((cv) => {
        return { name: cv?.name, id: cv?.id };
      });
      setCustomViewList((prev) => customViews);
    } catch (error) {}
  }

  async function getFields(module) {
    // Create a Request based on Custom View
    // Make a list of Fields used in that custom view

    const { displayName: moduleDisplayName, apiName: moduleApiName } = module;
    const { id: cvid, name: customViewName } = customView;
    let cvFields = [];
    try {
      const data = {
        moduleApiName,
        cvid,
        connname,
        orgid,
        apikey,
        datacenterurl,
      };
      const customViewData = await ApiCall.getCustomViewData(data);
      cvFields = parseFieldFromCriteria(
        customViewData?.data?.[0]?.criteria ?? {},
        []
      );
    } catch (error) {}

    // Get all fields of module
    try {
      const fieldResp = await ZOHO.CRM.META.getFields({
        Entity: moduleApiName,
      });

      const fields = fieldResp?.fields
        ?.filter((field) => {
          if (
            field.field_label &&
            field.api_name &&
            field.data_type === "text" &&
            !cvFields.includes(field?.api_name)
          ) {
            return true;
          }
        })
        .map((field) => {
          return {
            displayName: field.field_label,
            apiName: field.api_name,
          };
        });

      const urlFields = fieldResp?.fields
        ?.filter((field) => {
          if (
            field.field_label &&
            field.api_name &&
            !cvFields.includes(field?.api_name) &&
            (field.data_type === "website" || field.data_type === "textarea")
          ) {
            return true;
          }
        })
        .map((field) => {
          return {
            displayName: field.field_label,
            apiName: field.api_name,
          };
        });

      setFolderFieldList((prev) => fields);
      setUrlFieldList((prev) => urlFields);
    } catch (error) {}
  }

  useEffect(() => {
    async function getModules() {
      ZOHO.CRM.META.getModules().then(function (data) {
        let modules = data?.modules
          ?.filter((module) => module?.isBlueprintSupported)
          ?.map((module) => {
            return {
              displayName: module.plural_label,
              apiName: module.api_name,
            };
          });
        let filteredModule = modules?.filter(
          (element) => !inProgressModuleList.includes(element)
        );
        setModuleList(filteredModule);
      });
    }
    getModules();
  }, []);

  useEffect(() => {
    if (step == 1) {
      getCustomView(module);
    } else if (step == 2) {
      setTreeData((prev) => widState?.settingData?.[module?.apiName]?.treeData);
      getFields(module);
    } else if (step >= 4 && folder?.id) {
      if (checked && urlField?.apiName) {
        setValid(true);
      } else if (!checked) {
        setValid(true);
      }
    }
  }, [step]);

  useEffect(() => {
    if (module?.apiName != "") {
      getFields(module);
      setFolderField(null);
      setUrlField(null);
    }
  }, [customView?.id]);

  useEffect(() => {
    setCustomView((prev) => null);
    setFolderField((prev) => null);
    setUrlField((prev) => null);
  }, [module?.apiName]);

  return (
    <Box
      sx={{
        width: 1200,
        bgcolor: "#FAF9F6",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", height: 600, p: 3 }}>
          {" "}
          <Box
            sx={{
              // borderTopLeftRadius: "10px",
              // borderTopRightRadius: "10px",
              // pt: 2,
              // pb: 3,
              // px: 3.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box>
              <Typography sx={{ color: "#878787", mb: 1 }}>
                Select Module
              </Typography>

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  module?.apiName
                    ? {
                        displayName: module.displayName,
                        apiName: module.apiName,
                      }
                    : null
                }
                options={moduleList}
                getOptionLabel={(option) => option.displayName}
                onChange={(error, value) => {
                  if (value) {
                    setModule((prev) => {
                      return {
                        displayName: value.displayName,
                        apiName: value.apiName,
                      };
                    });
                    setFolder((prev) => {
                      return widState?.settingData?.[module?.apiName]
                        ?.rootFolder;
                    });
                    widState.setSelectedSetting(value.apiName);
                    setStep((prev) => 1);
                  } else {
                    setModule((prev) => {});
                    setStep((prev) => 0);
                  }
                }}
                size="small"
                sx={{ width: 550 }}
                renderInput={(params) => (
                  <TextField {...params} label="Module Name" />
                )}
              />
              <Typography sx={{ color: "#878787", fontSize: 14, ml: 2 }}>
                Select Field <span>(Where you want to save folder id)</span>
                <span>
                  (Fields present in
                  {customView?.name ? ` '${customView?.name}'` : ""} custom view
                  criteria will not be available)
                </span>
              </Typography>
            </Box>
          </Box>
          {/* <Divider /> */}
          {/* {step >= 1 && customViewList?.length > 0 && ( */}
          <Box>
            <Box
              sx={{
                // borderTopLeftRadius: "10px",
                // borderTopRightRadius: "10px",
                // pt: 2,
                // pb: 3,
                // px: 3.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box>
                <Typography sx={{ color: "#878787", mb: 1 }}>
                  Select Custom View
                </Typography>

                <Autocomplete
                  disablePortal
                  disabled={step >= 1 && customViewList?.length ? false : true}
                  id="combo-box-demo"
                  value={
                    customView?.id
                      ? {
                          name: customView?.name,
                          id: customView?.id,
                        }
                      : null
                  }
                  options={customViewList}
                  getOptionLabel={(option) => option.name}
                  onChange={(error, value) => {
                    if (value) {
                      setCustomView((prev) => {
                        return {
                          name: value?.name,
                          id: value?.id,
                        };
                      });
                      setStep((prev) => 2);
                    } else {
                      setCustomView((prev) => {});
                      setStep((prev) => 1);
                    }
                  }}
                  size="small"
                  sx={{ width: 550 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Custom View" />
                  )}
                />
                <Typography sx={{ color: "#878787", fontSize: 14, ml: 2 }}>
                  Select Field <span>(Where you want to save folder id)</span>
                  <span>
                    (Fields present in
                    {customView?.name ? ` '${customView?.name}'` : ""} custom
                    view criteria will not be available)
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* // )} */}
          {/* <Divider /> */}
          {/* {step >= 2 && folderFieldList?.length > 0 && ( */}
          <Box>
            <Box
              sx={{
                // borderTopLeftRadius: "10px",
                // borderTopRightRadius: "10px",
                // pt: 2,
                // pb: 3,
                // px: 3.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Autocomplete
                  disablePortal
                  disabled={step >= 2 && folderFieldList?.length ? false : true}
                  id="combo-box-demo"
                  value={
                    folderField?.apiName
                      ? {
                          displayName: folderField.displayName,
                          apiName: folderField.apiName,
                        }
                      : null
                  }
                  options={folderFieldList}
                  getOptionLabel={(option) => option.displayName}
                  onChange={(error, value) => {
                    if (value) {
                      setFolderField((prev) => {
                        return {
                          displayName: value.displayName,
                          apiName: value.apiName,
                        };
                      });
                      setStep((prev) => 3);
                    } else {
                      setFolderField((prev) => {});
                      setStep((prev) => 2);
                    }
                  }}
                  size="small"
                  sx={{ width: 550, mb: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Fields Name" />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                // borderTopLeftRadius: "10px",
                // borderTopRightRadius: "10px",
                // pt: 2,
                // pb: 3,
                // px: 3.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <FormGroup>
                  <FormControlLabel
                    disabled={folderField?.displayName ? false : true}
                    control={
                      <Checkbox checked={willSkip} onChange={handleWillSkip} />
                    }
                    label={`I dont want to create folder that are existing in 
                    ${folderField?.displayName} field`}
                  />
                </FormGroup>
                {/* <Typography sx={{ fontWeight: "bold", mb: 1.5 }}>
                  I dont want to create folder that are existing in '
                  {folderField?.displayName}' field
                </Typography>

                <Switch
                  checked={willSkip}
                  onChange={handleWillSkip}
                  defaultChecked={true}
                  inputProps={{ "aria-label": "controlled" }}
                /> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 600,
            width: "50%",
            borderRadius: "0 10px 10px 0",
            p: 3,
            bgcolor: "#FCFBFC",
            boxShadow: "-1px 0px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              // borderTopLeftRadius: "10px",
              // borderTopRightRadius: "10px",
              // pt: 2,
              // pb: 3,
              // px: 3.5,
              pl: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography sx={{ mb: 1, color: "#878787" }}>
                Root Folder
              </Typography>

              <RootFolderSelection
                source={"ProcessHistoricData"}
                setFolder={setFolder}
                setStep={setStep}
                folder={folder}
                isAdmin={isAdmin}
                settingData={
                  widState?.settingData?.[widState?.selectedSetting] ?? {}
                }
                module={widState?.selectedSetting ?? ""}
                apiData={widState?.apiData}
                saveApiData={(data) => {
                  widState?.setApiData(data);
                }}
                saveData={(data) => {
                  widState.setSettingData(data);
                }}
                folderDetails={widState?.folderDetails}
                setFolderDetails={(data) => {
                  widState?.setFolderDetails(data);
                }}
                addFolder={(data) => {
                  widState.addFolder(data);
                }}
                orgid={orgid}
                connname={connname}
                apikey={apikey}
                datacenterurl={datacenterurl}
                zuid={zuid}
                settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
              />
            </Box>
          </Box>
          {/* {folder?.id && step >= 3 && ( */}
          <Box
            sx={{
              mt: 3,
              bgcolor: "rgba(24, 80, 160, 0.04)",
              // borderBottomLeftRadius: "10px",
              // borderBottomRightRadius: "10px",
              // pt: 2.8,
              // pb: 5,
              // px: 3.5,
            }}
          >
            <Typography sx={{ fontWeight: "bold", pt: 2, p: 2 }}>
              Folder Structure{" "}
            </Typography>
            <MySortableTree
              allowMultipleRoots={false}
              moduleName={module?.apiName ?? ""}
              // myTreeData={
              //   widState?.settingData?.[module?.apiName]?.treeData ??
              //   baseTreeData
              // }
              myTreeData={treeData || baseTreeData}
              savedData={(data) => {
                saveTreeData(data);
                // widState.setSettingData(data);
              }}
            />
            {/* <MySortableTree
            allowMultipleRoots={false}
            moduleName={widState?.selectedSetting ?? ""}
            myTreeData={
              widState?.settingData?.[widState?.selectedSetting]?.treeData ?? []
            }
            savedData={(data) => {
              widState.setSettingData(data);
            }}
          /> */}
          </Box>
          <Box sx={{ mt: 14, display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              disabled={isValid ? false : true}
              variant="contained"
              onClick={createCronJob}
              sx={{ width: 220 }}
            >
              Create Cron Job
              {loading && (
                <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
              )}
            </Button>
          </Box>
          {/* // )} */}
        </Box>
      </Box>
      <SnackAlert
        duration={7000}
        snackOpen={open}
        handleCloseSnack={handleCloseSnackbar}
        severity={severity}
        message={message}
      />
    </Box>
  );
}

export default ProcessHistoricData;
