import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  createTheme,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSnapshot } from "valtio";
import { BuildTheme } from "../Components/RelatedList/srcfolder/xxassets/global/Theme-variable";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import RootFolderResponse from "./../Components/RelatedList/srcfolder/components/WorkDrive/CommonSection/RootFolderResponse";

import * as ApiCall from "./../Components/API/ApiCalling";
import { widState } from "../Components/RelatedList/srcfolder/store/workdriveState";
import AddCustomModule from "../Components/RelatedList/srcfolder/components/WorkDrive/Modals/AddCustomModule";
import error from "../Images/error.png";
import easyPluginz from "../Images/easypluginz-label.png";
import { height } from "@mui/system";

const ZOHO = window.ZOHO;
const crmVariableName = "crmVariableName"; // FIXME please change the value of crm variable

const theme = createTheme();

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "transparent",
  },
  "& .MuiTabs-root": {
    opacity: 0,
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },

  "& .MuiTabs-scroller.MuiTabs-fixed": {
    borderRadius: "20px",
  },
  "& .MuiTabs-flexContainer": {
    margin: "9px 5px",
    color: "#000",
  },
  "& .MuiTab-textColorInherit.Mui-selected": {
    backgroundColor: "#27b7fe",

    fontWeight: "bold",
    border: "1px solid black",
  },
  "& .MuiTab-root.Mui-selected": {
    color: `${theme.palette.common.black}`,
    backgroundColor: `${theme.palette.primary.light}`,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "0px 20px !important",
    boxShadow: "2px 0px 2px rgba(36, 126, 253, 0.2)",
  },
  transition: "width 3s",
  transitionDelay: "1s",
}));

function converConncectionMap(connMap) {
  return {
    clientId: connMap?.client_id,
    clientSecret: connMap?.client_secret,
    authUrl: connMap?.auth_url,
    refreshUrl: connMap?.refresh_url,
    refreshToken: connMap?.refresh_token,
    token_type: connMap?.token_type,
    connName: connMap?.conn_name,
    orgId: connMap?.org_id,
    apiKey: connMap?.api_key,
  };
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RelatedList(props) {
  const [Entity, setEntity] = useState(null);
  const [EntityId, setEntityId] = useState(null);

  const [recordData, setRecordData] = useState(null);
  const [rootFolderId, setRootFolderId] = useState(null);
  const [rootFolderData, setRootFolderData] = useState(null);
  const [value, setValue] = useState(0);
  const [newSettingCreated, setNewSettingCreated] = useState(false);
  const [newSetting, setNewSetting] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldApiName, setFieldApiName] = useState("");
  // const [stateSettings, setStateSettings] = useState([
  //   {
  //     settingId: "Workdrive",
  //     name: "Workdrive",
  //   },
  // ]);

  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [decryptData, setDecryptData] = useState([]);
  const [homeLock, setHomeLock] = useState(false);

  const settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL;
  const snap = useSnapshot(widState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const muiTheme = useTheme();

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        // ;
        setEntity((prev) => data?.Entity);
        setEntityId((prev) => data?.EntityId);

        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    widState.connName = "easyonedriveforcrm";
    const orgData = {
      apiKeys: [
        "easyonedriveforcrm__API_KEY",
        "easyonedriveforcrm__Organization_ID",
        "easyonedriveforcrm__ZAPI_Key",
        "easyonedriveforcrm__Data_Center_URL",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
    widState.apiKey =
      orgVariables?.Success?.Content?.easyonedriveforcrm__API_KEY?.value;
    widState.orgId =
      orgVariables?.Success?.Content?.easyonedriveforcrm__Organization_ID?.value;
    // TODO Please fix data center url
    widState.dataCenterUrl = "https://www.zohoapis.com";
    // widState.dataCenterUrl =  orgVariables?.Success?.Content?.easyonedriveforcrm__Data_Center_URL?.value;

    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();
    widState.zuid = orgResp?.org?.[0]?.primary_zuid;
  };

  useEffect(() => {
    if (zohoLoaded) {
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  // Fetch fetchRecordData
  useEffect(() => {
    async function fetchRecordData(params) {
      try {
        const recordDetails = await ZOHO.CRM.API.getRecord({
          Entity: Entity,
          RecordID: EntityId,
        });

        if (recordDetails?.error) {
          // TODO Show error
          return;
        }

        setRecordData((prev) => recordDetails?.data?.[0]);
      } catch (error) {}
    }
    if (EntityId && Entity) {
      fetchRecordData();
    }
  }, [Entity, EntityId]);

  // Fetch SettingData
  useEffect(() => {
    async function fetchSettingData() {
      try {
        const settingDetails = await ApiCall.getSettingData(
          Entity,
          "relatedList",
          "easyonedriveforcrm",
          widState.orgId,
          widState.apiKey,
          widState.dataCenterUrl,
          settingUrl
        );

        if (settingDetails?.error) {
          setNewSetting((prev) => true);
          snap?.setLoading(false);
          return;
        }

        if (settingDetails?.data?.length === 0) {
          setNewSetting((prev) => true);
          snap?.setLoading(false);
          return; // ; //TODO set setting for length of data 0
        }

        // ;
        const { setting_schema, setting_id, setting_type } =
          settingDetails?.data?.[0];
        const { fieldApiName } = JSON.parse(setting_schema);
        const { api_name, display_name } = fieldApiName;
        if (!recordData?.[api_name]) {
          snap?.setLoading(false);
          widState.setIsError(true);
          widState.setErrorMessage(
            "There is no folder id in '" +
              fieldApiName?.display_name +
              "' field of this module"
          );
          return;
        }
        // widState.setFieldApiName(api_name);
        setFieldApiName((prev) => api_name);
        setRootFolderId((prev) => recordData?.[api_name]);

        // const {setting_schema} = settingDetails?.error;
      } catch (error) {
        // ;
      }
    }

    if (recordData?.id || newSettingCreated) {
      fetchSettingData();
    }
  }, [recordData?.id, newSettingCreated]);

  useEffect(() => {
    async function fetchRootFolder(params) {
      try {
        const rootFolderDetails = await ApiCall.getFoldersData(
          rootFolderId,
          widState.connName,
          widState.orgId,
          widState.apiKey,
          widState.dataCenterUrl,
          settingUrl
        );
        if (rootFolderDetails?.error) {
          snap?.setLoading(false);
          widState.setIsError(true);
          widState.setErrorMessage(
            rootFolderDetails?.error +
              " or given One Drive folder id is not valid"
          ); // There is no Folder ID in Field Name in this record.
          return;
        }
        setRootFolderData((prev) => rootFolderDetails?.data);
        // ;
      } catch (error) {}
    }

    if (rootFolderId) {
      fetchRootFolder();
    }
  }, [rootFolderId, fieldApiName]);
  
  const customizer = {
    direction: "ltr",
    theme: "BLUE_THEME",
    activeMode: "light",
  };

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userDetails = await ZOHO.CRM.CONFIG.getCurrentUser();
        const user = userDetails?.users?.[0];
        if (user?.profile?.name == "Administrator") {
          setAdmin((prev) => true);
        }
        const fieldDetails = await ZOHO.CRM.META.getFields({ Entity: Entity });
        const moduleFields = fieldDetails?.fields
          ?.filter((field) => {
            return (
              field?.json_type == "string" &&
              field?.data_type == "text" &&
              field?.read_only == false
            );
          })
          .map((field) => {
            return {
              display_name: field?.field_label,
              api_name: field?.api_name,
            };
          });

        setFields((prev) => moduleFields);
      } catch (error) {}
    }
    if (zohoLoaded) {
      fetchUserData();
    }
  }, [zohoLoaded]);

  const theme = BuildTheme(customizer);

  if (zohoLoaded && widState.apiKey) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // bgcolor: `${
            //     homeLock === false && "rgba(127, 127, 127, 0.6)"
            // }`,
            // height: `${homeLock === false ? "700px" : ""}`,
            // p: 1
          }}
        >
          {/* {homeLock === true ? ( */}
          <Box sx={{ width: "100%" }}>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={snap.loading}
            >
              <CircularProgress color="secondary" />
            </Backdrop>
            <Box>
              {snap?.isError && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: "15px 0px",
                        mt: -3,
                      }}
                    >
                      <img src={error} alt="" width="150px" />
                    </Box>
                    <strong>{snap?.errorMessage}!</strong>
                  </Box>
                  <Box
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      right: 15,
                    }}
                  >
                    <img src={easyPluginz} alt="" width="220px" />
                  </Box>
                </Box>
              )}
            </Box>

            {rootFolderData && (
              <RootFolderResponse
                Entity={Entity}
                EntityId={EntityId}
                rootFolderId={rootFolderId}
                rootFolderData={rootFolderData}
                fieldApiName={fieldApiName}
                setFieldApiName={setFieldApiName}
                setRootFolderId={setRootFolderId}
                recordData={recordData}
                name={"One Drive"}
                isAdmin={isAdmin}
                fields={fields}
                settingId={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
                orgid={snap.orgId}
                connname={snap.connName}
                apikey={snap.apiKey}
                datacenterurl={snap.dataCenterUrl}
                settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
              />
            )}
            {newSetting && (
              <AddCustomModule
                module={Entity}
                connname={snap.connName}
                orgid={snap.orgId}
                apikey={snap.apiKey}
                datacenterurl={snap.dataCenterUrl}
                setNewSetting={setNewSetting}
                setNewSettingCreated={setNewSettingCreated}
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default RelatedList;
