import React, { useState } from "react";
import ActionAreaCard from "./../DisplaySection/DisplayPdf";

import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ShareIcon from "@mui/icons-material/Share";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { RiDeleteBin6Line, RiPencilLine } from "react-icons/ri";
import * as ApiCall from "../../../../../API/ApiCalling";
import "./../css/coupon.css";
import DisplayImage from "./../DisplaySection/DisplayImage";

import Folder from "./../DisplaySection/Folder";

import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { makeStyles, styled } from "@mui/styles";
import "./../css/project_folder.css";
import ListItems from "./ListItems";

import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import FileImageCard from "./../DisplaySection/DisplayFile";
import { changeName } from "./../Global/FileResponseChanger";
import ClipboardCopy from "../Modals/ClipboardCopy";
import { Box } from "@mui/system";
import { OpenInNewOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  grid: {
    border: "1px solid black",
  },
  icon: {
    fontSize: "25px !important",
  },
});

const ModalText = styled("span")({
  color: "#616364",
  marginLeft: "-5PX",
});

function CommonComponent({
  isAdmin,
  file,
  handleClick,
  settingId,
  setPost,
  post,
  settingUrl,
  orgid,
  connname,
  apikey,
  datacenterurl,
  setSnackOpen,
  moveCopyData,
  pasteData,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [sharedFileOpen, setSharedOpen] = useState(false);
  const [sharedLink, setSharedLink] = useState("");
  const [name, setName] = React.useState(file.name);
  const [id, setId] = React.useState("");
  const [showRightOptions, setShowRightOption] = useState(true);
  const snap = useSnapshot(widState);
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = async (data) => {
    snap.setLoading(true);
    setShareOpen((prev) => false);
    setOpen((prev) => false);
    let snackMessage = {
      bool: true,
      type: "error",
      message: "Request failed",
    };

    try {
      if (data?.email) {
        const response = await ApiCall.shareFileFolder(
          {},
          file,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );
        if (response?.error) {
          snackMessage = {
            bool: true,
            type: "error",
            message: response?.error?.errors[0]?.title,
          };
          return;
        }

        snackMessage = {
          bool: true,
          type: "success",
          message: "File shared successfully",
        };
        setSharedLink((prev) => response?.data?.url);
        setSharedOpen((prev) => true);

        // // ;
      } else if (data?.name) {
        const response = await ApiCall.renameFileFolder(
          { name: data?.name },
          file,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (response?.data) {
          let tempResponse = changeName(response, file);
          // let tempResponse = changeName(response, response?.data)

          // ;
          // ;
          let tempArray = post.map((file) =>
            // file.id !== response.data?.id ? file : tempResponse,
            file.id !== tempResponse?.id ? file : response.data
          );
          widState?.setAddItemSettingData(
            settingId,
            tempResponse.parent_id,
            tempArray
          );
          snackMessage = {
            bool: true,
            type: "success",
            message: "Renamed successfully",
          };
          setPost(tempArray);
        } else {
          snackMessage = {
            bool: true,
            type: "error",
            message: response?.error,
          };
        }

        // // ;
      } else {
        const response = await ApiCall.shareFileFolder(
          {},
          file,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (response?.error) {
          // ;
          snackMessage = {
            bool: true,
            type: "error",
            message: response?.error?.errors?.[0]?.title,
          };
          return;
        }

        snackMessage = {
          bool: true,
          type: "success",
          message: "File shared successfully",
        };
        setSharedLink((prev) => response?.data?.url);
        setSharedOpen((prev) => true);

        // // ;
      }
      snap.setSnackbar(snackMessage);
    } catch (error) {
      snap.setSnackbar(snackMessage);
    }
    snap.setLoading(false);
  };

  const shareFullAccess = async () => {
    snap.setLoading(true);
    setShareOpen((prev) => false);
    setOpen((prev) => false);
    let snackMessage = {
      bool: true,
      type: "error",
      message: "Request failed",
    };

    const response = await ApiCall.shareFullAccess(
      {},
      file,
      connname,
      orgid,
      apikey,
      datacenterurl
    );

    if (response?.error) {
      snackMessage = {
        bool: true,
        type: "error",
        message: response?.error,
      };
      return;
    }

    snackMessage = {
      bool: true,
      type: "success",
      message: "File shared successfully",
    };

    setSharedLink((prev) => response?.data?.url);
    setSharedOpen((prev) => true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShareOpen(false);
    setSharedOpen((prev) => false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false);
  };

  const copyCoupon = (e, data) => {
    // // ;
  };

  const deleteData = (e, data) => {
    snap?.setLoading(true);
    handleCloseDeleteDialog();
    data = file;
    ApiCall.deleteFileFolder(
      data,
      connname,
      orgid,
      apikey,
      datacenterurl,
      settingUrl
    )
      .then((response) => {
        // ;
        if (response?.data) {
          let xArray = post.filter((file) => file.id != data.id);
          // setSnackOpen(true);
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "File deleted successfully",
          });
          setPost(xArray);

          let lastIndex =
            widState?.settingData?.[settingId]?.breadCrumbs?.length - 1;
          let lastIndexId =
            widState?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
          widState?.setAddItemSettingData(settingId, lastIndexId, xArray);
        } else {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message:
              "Couldn't delete the file. Please check your internet connection or other setting.",
          });
        }

        // if (tempView) {
        //   widstate?.setView("grid");
        // }
        snap?.setLoading(false);
      })
      .catch((error) => {
        // alert(error);

        // if (tempView) {
        //   widstate?.setView("grid");
        // }
        snap.setSnackbar({
          bool: true,
          type: "error",
          message:
            "Couldn't delete the file. Please check your internet connection or other setting.",
        });
        snap?.setLoading(false);

        // // ;
      });
  };

  const handleView = (file) => {
    if (snap?.view == "list" && file?.type !== "folder") {
      return (
        <ListItems
          setShowRightOption={setShowRightOption}
          file={file}
          settingId={settingId}
          orgid={orgid}
          connname={connname}
          apikey={apikey}
          datacenterurl={datacenterurl}
          settingUrl={settingUrl}
        />
      );
    }
    return (
      <InputDecider
        setShowRightOption={setShowRightOption}
        file={file}
        settingId={settingId}
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        settingUrl={settingUrl}
        handleClick={handleClick}
        post={post}
        setSnackOpen={setSnackOpen}
        setPost={setPost}
      />
    );
  };

  return (
    <>
      <ContextMenuTrigger id={file?.id}>{handleView(file)}</ContextMenuTrigger>
      {showRightOptions && (
        <ContextMenu style={{ width: 350 }} id={file.id}>
          <MenuItem data={file} onClick={handleClickOpen}>
            <RiPencilLine className="RiPencilLine" />
            <ModalText> Rename </ModalText>
          </MenuItem>
          {file?.type != "folder" && (
            <MenuItem
              data={{
                type: "copy",
                file: file,
                parentFiles: post,
              }}
              onClick={moveCopyData}
            >
              <ContentCopyIcon className="RiPencilLine" />
              <ModalText> Copy </ModalText>
            </MenuItem>
          )}
          {snap?.pasteOpen && file?.type == "folder" && (
            <MenuItem
              data={{
                pasteFile: file,
                pastePost:
                  snap?.settingData?.[settingId]?.previousData?.[file?.id],
              }}
              onClick={pasteData}
            >
              <ContentPasteIcon className="RiPencilLine" />
              <ModalText> Paste </ModalText>
            </MenuItem>
          )}
          <MenuItem
            data={{
              type: "move",
              file: file,
              parentFiles: post,
            }}
            onClick={moveCopyData}
          >
            <DriveFileMoveIcon className="RiPencilLine" />
            <ModalText> Cut </ModalText>
          </MenuItem>
          {isAdmin && (
            <MenuItem
              data={file}
              onClick={async () => {
                window.open(
                  file?.type == "folder"
                    ? `https://onedrive.live.com/?id=${encodeURI(file?.id)}`
                    : `https://onedrive.live.com/?id=${encodeURI(
                        file?.id
                      )}&o=OneUp`,
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              <OpenInNewOutlined className="RiPencilLine" />
              <ModalText> Open in One Drive </ModalText>
            </MenuItem>
          )}

          {file?.type != "folder" && (
            <MenuItem
              onClick={async () => {
                let response = await ApiCall.getImageResponse(
                  file,
                  connname,
                  orgid,
                  apikey,
                  datacenterurl,
                  settingUrl
                );
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file.name); //or any other extension
                document.body.appendChild(link);
                link.click();
              }}
            >
              <DownloadOutlinedIcon className="RiPencilLine" />
              <ModalText> Download </ModalText>
            </MenuItem>
          )}
          
          {isAdmin && (
            <>
              <MenuItem
                onClick={() => {
                  // setShareOpen(true);
                  onSubmit();
                }}
              >
                <SendAndArchiveIcon className="RiPencilLine" />
                <ModalText> Ext. Share Link(Download Only)</ModalText>
              </MenuItem>

            </>
          )}

          <MenuItem
            data={file}
            onClick={() => {
              setDeleteOpen(true);
            }}
          >
            <RiDeleteBin6Line className="delete" />
            <ModalText> Delete </ModalText>
          </MenuItem>
        </ContextMenu>
      )}

      <Dialog
        open={deleteOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Delete Item"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pb: 2,
            }}
          >
            Do you really want to delete this item?
          </Box>
          <div style={{ display: "flex", justifyContent: "end", gap: 3 }}>
            <Button
              sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
              onClick={handleCloseDeleteDialog}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              color="error"
              onClick={deleteData}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Rename"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              defaultValue={file?.name?.split(".")?.[0]}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="standard-basic"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
                onClick={handleClose}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
                type="submit"
                variant="contained"
              >
                Rename
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="share-popup"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              padding: "10px",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => {
                // // ;
                return (
                  <TextField
                    id="standard-basic"
                    // variant= "standard"
                    {...field}
                    error={
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        field?.value
                      )
                        ? false
                        : true
                    }
                    variant="standard"
                    helperText={
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        field?.value
                      )
                        ? "You are good to go"
                        : "Enter a valid email"
                    }
                    fullWidth
                  />
                );
              }}
            />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
                type="submit"
                variant="contained"
              >
                Share
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sharedFileOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // maxWidth: "400px", // Set your width here
              padding: "10px",
              wordWrap: "break-word",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{ padding: "15px", backgroundColor: "#001E3C", color: "#fff" }}
          >
            <Grid xs={11}>{sharedLink}</Grid>
            <Grid xs={1} sx={{ paddingLeft: "5px" }}>
              <ClipboardCopy copyText={sharedLink} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CommonComponent;

const InputDecider = ({
  setShowRightOption,
  file,
  settingId,
  handleClick,
  post,
  orgid,
  connname,
  apikey,
  datacenterurl,
  settingUrl,
  setSnackOpen,
  setPost,
}) => {
  const classes = useStyles();
  const snap = useSnapshot(widState);

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.background = "#EDF6FF";
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.background = "";
  }

  const handleStart = (event, file) => {
    setShowRightOption((prev) => false);

    event.dataTransfer.setData(
      "moveData",
      JSON.stringify({
        fileName: file?.file?.name,
        dropFile: event?.target?.id,
        fileType: file?.file?.type,
        dropFileParentId: file?.file?.parent_id,
      })
    );
    setTimeout(() => {
      setShowRightOption((prev) => true);
    }, 1000);
  };

  const handleDrop = (event, file) => {
    event.preventDefault();
    event.target.style.background = "";
    var moveData = JSON.parse(event.dataTransfer.getData("moveData"));
    if (!moveData?.dropFile) {
      return;
    }

    if (moveData?.dropFile === file?.id) {
      return;
    }

    snap?.setLoading(true);

    handleDragLeave(event);
    // let tempView = snap?.view;
    // if (tempView) {
    //   widstate?.setView("list");
    // }

    if (moveData?.dropFile && moveData?.dropFile !== file?.id) {
      ApiCall.moveFile(
        file,
        {
          id: moveData?.dropFile,
          parent_id: moveData?.dropFileParentId,
        },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      )
        .then((response) => {
          if (response?.data) {
            let xArray = post.filter((file) => file.id != moveData?.dropFile);
            setPost(xArray);
            // if (tempView) {
            //   widstate?.setView("grid");
            // }
            let lastIndex =
              widState?.settingData?.[settingId]?.breadCrumbs?.length - 1;
            let lastIndexId =
              widState?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
            widState?.setAddItemSettingData(
              settingId,
              moveData?.dropFileParentId,
              xArray
            );
            // widState?.setApiSettingData(settingId, moveData?.dropFileParentId, xArray);

            if (
              widState?.settingData?.[settingId]?.previousData?.hasOwnProperty(
                `${file?.id}`
              )
            ) {
              let tempArray = JSON.parse(
                JSON.stringify(
                  widState?.settingData?.[settingId]?.previousData?.[file?.id]
                )
              );
              tempArray = tempArray.concat([response?.data]);
              // // ;
              widState?.setAddItemSettingData(settingId, file?.id, tempArray);
              // snap?.setApiSettingData(settingId, file?.id, tempArray);
            }

            snap.setSnackbar({
              bool: true,
              type: "success",
              message: "Item moved successfully",
            });
          } else {
            // if (tempView) {
            //   widstate?.setView("grid");
            // }
            snap.setSnackbar({
              bool: true,
              type: "error",
              message: "Item moved failed",
            });
          }

          snap.setLoading(false);
          // event.dataTransfer.setData("moveData", null);
          // // ;
        })
        .catch((error) => {
          snap.setLoading(false);
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: "There is an error while moving. Check Internet",
          });
          // // ;
        });
    }
  };

  let inputProps = {
    file,
    handleClick,
  };

  switch (file?.type) {
    case "folder":
      return (
        <Grid
          onClick={() => {
            handleClick(
              file,
              widState?.settingData?.[settingId]?.previousData?.[file?.id]
            );
          }}
          {...inputProps}
          id={file.id}
          draggable="true"
          onDragStart={(e) => handleStart(e, { file: file })}
          onDrop={(e) => {
            handleDrop(e, file);
          }}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          <Folder
            icon={<FolderOutlinedIcon className={classes.icon} />}
            file={file}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Grid>
      );
      break;
    case "image":
      // // ;
      return (
        <Grid
          draggable="true"
          id={file.id}
          onDragStart={(e) => handleStart(e, { file: file })}
          Dragend
          sx={{
            "&:hover": {
              boxShadow: "-0px 0px 11px 1px rgba(0,0,0,0.20)",
            },
          }}
        >
          <DisplayImage
            file={file}
            settingId={settingId}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Grid>
      );
      break;
    case "pdf":
      return (
        <Grid
          draggable="true"
          id={file.id}
          onDragStart={(e) => handleStart(e, { file: file })}
          sx={{
            "&:hover": {
              boxShadow: " -0px 0px 11px 1px rgba(0,0,0,0.20)",
            },
          }}
        >
          <ActionAreaCard
            file={file}
            settingId={settingId}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Grid>
      );
      break;
    case "video":
      return (
        <Grid
          draggable="true"
          id={file.id}
          onDragStart={(e) => handleStart(e, { file: file })}
          sx={{
            "&:hover": {
              boxShadow: " -0px 0px 11px 1px rgba(0,0,0,0.20)",
            },
          }}
        >
          <ActionAreaCard
            file={file}
            settingId={settingId}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Grid>
      );
      break;
    default:
      return (
        <Grid
          draggable="true"
          id={file.id}
          onDragStart={(e) => handleStart(e, { file: file })}
          sx={{
            "&:hover": {
              boxShadow: " -0px 0px 11px 1px rgba(0,0,0,0.20)",
            },
          }}
        >
          <FileImageCard
            file={file}
            settingId={settingId}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Grid>
      );
      break;
  }
  return <></>;
};
